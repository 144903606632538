'use client';
import { ReactNode } from 'react';
import Header from '../../components/navigation/Header';

type Props = {
  children: ReactNode;
};

// A layout which does not load customer projects if authenticated
export default function WithoutCustomerProjectsLayout(props: Props) {
  const { children } = props;

  return (
    <>
      <Header isAuthenticated={false} />
      <div className="content-with-side-menu">{children}</div>
    </>
  );
}
