'use client';
import { useContext } from 'react'
import { AppContext } from '@/app/appProvider';
import { useParams } from 'next/navigation';
import { analytics } from '@/app/utils/segment';
import { EventProperties } from '@segment/analytics-next';
import { CustomEventProperties, TrackingEvents } from '../utils/trackingEvents';
import { getCategoryByWorkOrderId } from '../services/categoryService';

const useTracking = () => {
  const events = TrackingEvents;

  const { srcPartnerId, categories } = useContext(AppContext);
  const { workOrderId } = useParams();
  const category = getCategoryByWorkOrderId(+workOrderId, categories);

  const preparePayload = (payload: EventProperties) => {
    /** Here we can extend every payload **/
    return {
      customProperties: { ...payload, srcPartnerId, categoryId: category?.categoryId, categoryName: category?.categoryName },
    };
  };

  const trackEvent = (name: string | TrackingEvents, payload: EventProperties & CustomEventProperties = {}) => {
    analytics?.track(name, preparePayload(payload));
  };

  const trackClick = (name: string | TrackingEvents, payload: EventProperties & CustomEventProperties = {}) => {
    trackEvent(name, { ...payload, "action": "click" });
  };

  // use to track when user register, login or update profile
  const identifyEvent = (userId: number | TrackingEvents, payload: EventProperties = {}) => {
    analytics?.identify(userId.toString(), preparePayload(payload));
  };

  return {
    events,
    trackEvent,
    trackClick,
    identifyEvent,
  };
};

export default useTracking;
