'use client';
import { ReactNode, useEffect, useState } from 'react';
import useOpenToggle from '../../hooks/useOpenToggle';
import Header from '../../components/navigation/Header';
import { useIsAuthenticated } from '../../hooks/useIsAuthenticated';
import { ByNavMenu } from '../../components/icons/CustomIconPack';
import classNames from 'classnames';
import { useScreenWidth } from '../../hooks/useScreenWidth';
import { useGetCustomerProjects } from '../../hooks/queries/useProject';
import Sidebar from '../../components/navigation/Sidebar';
import useTracking from '@/app/hooks/useTracking';
import useJWTData from '../../hooks/useJWTData';
import { isEligibleForMyProjectsSpa } from '../../utils/myProjectsSpaEligibilityRule';
import LiveChat from '../../components/live-chat/LiveChat';
import { useGetFeatures } from '../../hooks/queries/useGetFeatures';
import { NavEventProperties } from '../../utils/trackingEvents';
import { NavbarToggler } from 'reactstrap';
import { NextAuthErrors } from '@/app/utils/constants';
import { getSession, signOut } from 'next-auth/react';
import routes from '@/app/utils/routes';
import { useWindowsFocus } from '@/app/hooks/useWindowFocus';

type Props = {
  children: ReactNode;
};

// A layout which loads customer projects if authenticated
export default function CustomerProjectsLayout(props: Props) {
  const { children } = props;
  const { isOpen, toggle, close, open } = useOpenToggle();
  const { isAuthenticated } = useIsAuthenticated();
  const { isLargeDesktop } = useScreenWidth();
  const { events, trackClick, identifyEvent } = useTracking();
  const { jwtData } = useJWTData();
  const [userId, setUserId] = useState<number | null>(null);
  const isWindowFocused = useWindowsFocus();

  const queryGetCustomerProjects = useGetCustomerProjects();
  const { isLoading } = useGetFeatures(isAuthenticated);

  const trackingProps: NavEventProperties = {
    component: 'LeftNavLayout',
    target: 'LeftNav Toggled',
  };

  // Open nav menu if we have room, close if we don't
  useEffect(() => {
    isLargeDesktop && open();
    !isLargeDesktop && close();
  }, [isLargeDesktop]);

  useEffect(() => {
    if (jwtData?.id) {
      if (+jwtData?.id !== userId) {
        identifyEvent(+jwtData.id, { email: jwtData.emailId });
        setUserId(+jwtData.id);
      }
    }
  }, [jwtData, userId]);

  const navMenuButton = (
    <NavbarToggler
      onClick={() => {
        toggle();
        trackClick(events.BUTTON_CLICKED, trackingProps);
      }}
    >
      <ByNavMenu size={36} />
    </NavbarToggler>
  );

  const isEligible = isEligibleForMyProjectsSpa(queryGetCustomerProjects?.data);

  useEffect(() => {
    const synchronizeSession = async () => {
      /** This call returns the fresh session e.g. decorated with error, and updates the current one */
      const session = await getSession();
      if (session?.error === NextAuthErrors.RefreshErr) {
        signOut({ redirect: false, callbackUrl: routes.login });
      }
    };

    if (isWindowFocused && isAuthenticated) synchronizeSession();
  }, [isWindowFocused, isAuthenticated]);

  return (
    <>
      <Header
        navMenuButton={isAuthenticated && isEligible && navMenuButton}
        isAuthenticated={isAuthenticated}
      />
      <div
        className={classNames({
          ['content-with-side-menu']: !isOpen,
          ['content-with-side-menu-expanded']: isAuthenticated && isLargeDesktop && isOpen,
        })}
      >
        {queryGetCustomerProjects?.isSuccess && isEligible && (
          <>
            <Sidebar
              isOpen={isOpen}
              toggle={() => {
                toggle();
                trackClick(events.BUTTON_CLICKED, trackingProps);
              }}
              close={close}
              customerProjects={queryGetCustomerProjects?.data}
            />
            {!isLoading && <LiveChat />}
          </>
        )}
        {children}
      </div>
    </>
  );
}
