import { useSession } from 'next-auth/react';
import { useMemo } from 'react';

export const useIsAuthenticated = () => {
  const { status, data } = useSession();
  const isAuthenticated = useMemo(() => status === 'authenticated', [status, data?.error]);

  return {
    isAuthenticated,
  };
};
