import { useEffect, useState } from 'react';

export function useWindowsFocus(defauleValue = false): boolean {
  const [focused, setFocused] = useState(defauleValue);

  useEffect(() => {
    setFocused(window.document.hasFocus());
  }, []);

  useEffect(() => {
    window.addEventListener('blur', (e) => {
      setFocused(false);
    });
  });

  useEffect(() => {
    window.addEventListener('focus', (e) => {
      setFocused(true);
    });
  });

  return focused;
}
