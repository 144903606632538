'use client';
import CustomerProjectsLayout from '@/app/components/layouts/CustomerProjectsLayout';
import WithoutCustomerProjectsLayout from '@/app/components/layouts/WithoutCustomerProjectsLayout';
import routes from '@/app/utils/routes';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export default function LeftNavLayout({ children }: Props) {
  const pathname = usePathname();

  // Cases where we don't want to automatically load customer projects if authenticated
  const withoutCustomerProjects = [routes.resetPassword, routes.setPassword];

  if (withoutCustomerProjects.includes(pathname)) {
    return <WithoutCustomerProjectsLayout>{children}</WithoutCustomerProjectsLayout>;
  }

  return <CustomerProjectsLayout>{children}</CustomerProjectsLayout>;
}
