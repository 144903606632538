'use client';
import React, { ReactNode } from 'react';
import { getRetailerConfig, getRetailerMappingByHostname } from '@/app/services/retailerService';
import dynamic from 'next/dynamic';

type Props = {
  children: ReactNode;
};

/** Client component entrypoint, which decides which Theme to load. Theme will be a server component to avoid layout shift. */
const ThemeLayout = ({ children }: Props) => {
  const windowEl = typeof window !== undefined ? window : null;
  const srcPartnerId =
    windowEl && getRetailerMappingByHostname(windowEl.location.hostname).srcPartnerId;
  const config = getRetailerConfig(srcPartnerId ?? 0);

  if (config) {
    const Theme = config.theme;
    return <Theme>{children}</Theme>;
  }

  return <>{children}</>;
};

export default dynamic(() => Promise.resolve(ThemeLayout), { ssr: false });
