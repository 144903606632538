'use client';
import AppProvider from '@/app/appProvider';
import { toast } from '@/app/components/shared/Alerts/Toast';
import { registerValidators } from '@/app/utils/validators';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { SessionProvider } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { ReactNode, useState } from 'react';
import { setLocale } from 'yup';

type Props = {
  children?: ReactNode;
};

export const NextProvider = ({ children }: Props) => {
  const t = useTranslations('errors');

  const handleError = (error: AxiosError) => {
    if (error?.response?.status === 500) {
      toast({ header: t('500'), toastId: 'Error500' });
    }
  };

  const [client] = useState(
    new QueryClient({
      defaultOptions: {
        queries: { refetchOnWindowFocus: false, refetchOnReconnect: false, retry: false },
      },
      queryCache: new QueryCache({
        onError: async (error) => {
          handleError(error as AxiosError);
        },
      }),
      mutationCache: new MutationCache({
        onError: async (error) => {
          handleError(error as AxiosError);
        },
      }),
    }),
  );

  registerValidators();

  // Customise Yup's default error messages:
  // https://github.com/jquense/yup#error-message-customization
  setLocale({
    mixed: { required: () => `Required` },
  });

  return (
    <QueryClientProvider client={client}>
      <SessionProvider>
        <AppProvider>{children}</AppProvider>
      </SessionProvider>
    </QueryClientProvider>
  );
};
