import config from '@/app/config/config';
import { RetailerConfig } from '@/app/types/models';
import { SrcPartnerEnum } from '../utils/constants';

export const getRetailerConfig = (srcPartnerId: number): RetailerConfig | undefined =>
  config.find((r) => r.srcPartnerId === srcPartnerId);

export const getRetailerPlaceholderImageUrl = (srcPartnerId: number) =>
  `/images/placeholders/${getRetailerConfig(srcPartnerId)?.placeholderImageUrl}`;

type RetailerMappingValue = { srcPartnerId: number; name: string };

type RetailerMapping = {
  [subdomain: string]: RetailerMappingValue;
};

export const getRetailerMappingByHostname = (hostname: string): RetailerMappingValue => {
  const retailerDomain = hostname.substring(0, hostname.indexOf('.'));
  const retailerMappingList: RetailerMapping[] = JSON.parse(
    process.env.NEXT_PUBLIC_RETAILER_DOMAIN_MAPPING,
  );

  const retailer = retailerMappingList.find((rn: RetailerMapping) => {
    const retailerMappingKey = Object.keys(rn)[0];
    if (retailerMappingKey === retailerDomain) return true;
  });

  if (retailer) {
    return retailer[retailerDomain as keyof typeof SrcPartnerEnum]; // why keyof typeof RetailerEnum?
  }

  return { srcPartnerId: -1, name: '' };
};

export const getHostnameBySrcPartnerId = (srcPartnerId: SrcPartnerEnum): string => {
  const retailerMappingList: RetailerMapping[] = JSON.parse(
    process.env.NEXT_PUBLIC_RETAILER_DOMAIN_MAPPING,
  );

  const retailer = retailerMappingList.find((rn: RetailerMapping) => {
    const retailerMappingKey = Object.keys(rn)[0];
    if (rn[retailerMappingKey].srcPartnerId == srcPartnerId) return true;
  });

  if (retailer) {
    return Object.keys(retailer)[0];
  }

  return '';
};

export const getRetailerFaviconUrl = (srcPartnerId: number) => {
  const retailerConfig = getRetailerConfig(srcPartnerId);
  const faviconUrl = retailerConfig?.faviconUrl ?? 'favicon.ico';
  return `images/favicons/${faviconUrl}`;
};
