import { DateTime } from 'luxon';
import { FormatDateEnum, getEnvironment, LangEnum } from '@/app/utils/constants';
import { DocumentLink } from '../types/models';

export const getWeekdayName = (date: string | null | Date, locale: LangEnum = LangEnum.EN_US) => {
  if (!date) return null;
  const dateString = typeof date === 'string' ? date : date.toISOString();
  return DateTime.fromISO(dateString, { zone: 'utc', locale }).weekdayLong;
};

export const formatDate = (date: string | null | Date, format = FormatDateEnum.DATE_TIME) => {
  const invalidDate = '-';
  if (!date) return invalidDate;
  const dateString = typeof date === 'string' ? date : date.toISOString();
  const formattedDate = DateTime.fromISO(dateString, { zone: 'utc' }).toFormat(format);
  const isInvalidDate = formattedDate.includes('Invalid');
  return isInvalidDate ? invalidDate : formattedDate;
};

export const addHoursForward = (date: string | Date | null, addHours: number): string => {
  const invalidDate = '-';
  if (!date) return invalidDate;
  const dateTime =
    typeof date === 'string' ? DateTime.fromJSDate(new Date(date)) : DateTime.fromJSDate(date);
  const newDateTime = dateTime.plus({ hours: addHours });
  // format the date without the timezone offset and with the T
  const formattedDate = newDateTime.toFormat("yyyy-MM-dd'T'HH:mm:ss");
  return formattedDate || invalidDate;
};

/**
 * Converts Date to the short format - 8/17/2023 (US format fow now)
 * Should be adjusted for other locales in the future
 */
export const getShortDate = (date: Date) => date.toLocaleString('en-US', { dateStyle: 'short' });

export const openPDF = (pdfUrl: string) => {
  window.open(pdfUrl, '_blank');
};

// Format 10 digit string as xxx-xxx-xxxx
export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber ? phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : '';
};

// Format 10 digit string as (xxx) xxx-xxxx
export const formatPhoneNumberWithParens = (phoneNumber?: string) => {
  return phoneNumber ? phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '';
};

/**
 * Method for getting and parsing file name from Content-Disposition header
 * for example `attachment; filename=\"34711141 - Project Estimate.pdf\"; filename*=UTF-8''34711141%220-%20Project Estimate.pdf`
 * -> 34711141 - Project Estimate.pdf
 */
export const getFileNameFromContentDispositionHeader = (input: string) => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(input);
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  }
  return '';
};

export const savePDF = (file: any, fileName?: string) => {
  const defaultFileName = 'File';
  const blob = new Blob([file], { type: 'application/pdf' });
  const fileURL = window.URL.createObjectURL(blob);
  let alink = document.createElement('a');
  alink.href = fileURL;
  const finalFileName = fileName || defaultFileName;
  alink.download = `${finalFileName.includes('.pdf') ? finalFileName : `${finalFileName}.pdf`}`;
  alink.click();
};

export const isRouteInPathname = (route: string, pathname: string) =>
  pathname.indexOf(route) !== -1;

export const convertSecondsToMilliseconds = (seconds: number) => seconds * 1000;

export const addQueryParameterToRoute = (
  route: string,
  target: string,
  paramName: string,
  paramVal: string,
) => {
  if (route.includes(target)) {
    if (route.includes('?')) {
      return `${route}&${paramName}=${paramVal}`;
    } else {
      return `${route}?${paramName}=${paramVal}`;
    }
  }
  return route;
};

// Replace tokens like {0} in order with arg value
export const stringFormat = (str: string, ...args: string[]): string => {
  return str.replace(/{(\d+)}/g, (match, index) => args[index] || '');
};

export const getFormattedShoppingListUrl = (
  shoppingListDoc: DocumentLink | undefined,
  workOrderId: string,
  projectId: number,
) => {
  const shoppingListFormattedUrl = shoppingListDoc
    ? stringFormat(shoppingListDoc.downloadUrl, projectId.toString(), workOrderId)
    : '';

  return shoppingListFormattedUrl;
};

export const isWhitespaceString = (str: string) => !str.replace(/\s/g, '').length;

export const stripPhoneFormatting = (value: string) => {
  return value.replace(/\D/g, '');
};

export const replaceEnvInUrl = (url: string) => {
  // Extract the protocol and rest of the URL
  const protocolSeparator = '://';
  const [protocol, restOfUrl] = url.split(protocolSeparator);

  const currentEnv = getEnvironment(process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT);

  // Determine the environment prefix
  let envToken = '';
  switch (currentEnv) {
    case 'development':
      envToken = 'dev';
      break;
    case 'staging':
      envToken = 'staging';
      break;
    case 'uat':
      envToken = 'uat';
      break;
    case 'production':
      envToken = '';
      break;
    default:
      envToken = '';
  }

  const updatedUrl = `${protocol}${protocolSeparator}${envToken}${restOfUrl}`;
  return updatedUrl;
};
