import React from 'react';

export default function NorthernToolLogo() {
  return (
    <svg
      width="124"
      height="48"
      viewBox="0 0 124 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.062 38.0689C13.2691 38.0689 6.52348 26.5146 11.92 17.2713C17.3164 8.02804 30.8076 8.02804 36.2039 17.2713C37.4345 19.379 38.0823 21.7701 38.0823 24.2039C38.0734 31.8578 31.8016 38.0601 24.062 38.0689ZM24.062 12.1542C14.6858 12.1524 8.82387 22.189 13.5102 30.2199C18.1968 38.2509 29.917 38.2532 34.6068 30.224C35.6757 28.3936 36.2388 26.3174 36.2397 24.2039C36.2358 17.5532 30.7872 12.1617 24.062 12.1542Z"
        fill="#F5B100"
      />
      <path
        d="M21.7054 19.3726L25.822 22.0662V19.4203L29.7734 19.418V16.7312H18.3414V19.3794L21.7054 19.3726Z"
        fill="#F5B100"
      />
      <path
        d="M21.3703 20.1555L15.5098 20.1669V30.0926L21.3933 30.0812C21.3933 30.0812 21.3933 27.399 21.3933 26.371L27.0611 30.0948H32.6142V20.2077H26.6205V23.5435L21.4989 20.1555H21.3703Z"
        fill="#F5B100"
      />
      <path
        d="M22.185 32.2256L25.9046 32.2438V30.3081L22.1918 27.8437L22.185 32.2256Z"
        fill="#F5B100"
      />
      <path
        d="M42.8125 16.7984H45.587L47.4481 19.8922C47.9048 20.7368 48.035 20.9807 48.4574 21.8953C48.3867 21.0959 48.3752 20.922 48.3501 20.0074V16.7984H50.8004V24.9756H47.987L46.3132 22.1505C45.8939 21.4531 45.5128 20.7339 45.1714 19.9961C45.2308 20.7368 45.2422 20.922 45.2536 21.6627V24.9756H42.8125V16.7984Z"
        fill="white"
      />
      <path
        d="M54.2532 20.8859C54.2532 22.3131 54.84 23.169 56.08 23.169C57.1327 23.169 57.8726 22.347 57.8726 20.8068C57.8839 20.4418 57.8321 20.0775 57.7196 19.7296C57.613 19.3843 57.395 19.0831 57.0989 18.8718C56.8028 18.6606 56.445 18.551 56.08 18.5599C55.1437 18.5599 54.2532 19.1899 54.2532 20.8859ZM60.5056 20.8068C60.5056 22.8777 59.2747 25.2421 56.0321 25.2421C52.6478 25.2421 51.6293 22.758 51.6293 20.8994C51.6293 18.5599 53.0931 16.498 56.08 16.498C59.3478 16.498 60.5056 18.8602 60.5056 20.8068Z"
        fill="white"
      />
      <path
        d="M63.972 18.5124V20.4252H65.0613C65.4952 20.4252 66.3264 20.4004 66.3264 19.4745C66.3264 18.5486 65.5043 18.5124 65.0499 18.5124H63.972ZM61.3505 16.7984H65.4723C66.2556 16.7984 67.0046 16.8458 67.5093 17.0536C68.61 17.5165 68.9365 18.6344 68.9365 19.4157C68.9461 19.7177 68.8954 20.0185 68.7872 20.301C68.6791 20.5835 68.5156 20.8421 68.3062 21.062C68.0052 21.3484 67.6385 21.5584 67.2375 21.674L69.2494 24.9756H66.3492L64.7507 22.0918H63.9766V24.9756H61.3505V16.7984Z"
        fill="white"
      />
      <path
        d="M69.238 16.7871H76.043V18.815H73.9467V24.9756H71.3366V18.815H69.238V16.7871Z"
        fill="white"
      />
      <path
        d="M76.6961 16.7984H79.3062V19.7748H81.8022V16.7984H84.4351V24.9756H81.8022V21.8253H79.3062V24.9756H76.6961V16.7984Z"
        fill="white"
      />
      <path
        d="M86.2049 16.7984H92.7519V18.5937H88.8287V19.8109H92.4916V21.5814H88.8287V23.2029H93.1013V24.9756H86.2049V16.7984Z"
        fill="white"
      />
      <path
        d="M96.4033 18.5124V20.4252H97.4926C97.9265 20.4252 98.7577 20.4004 98.7577 19.4745C98.7577 18.5486 97.9379 18.5124 97.4812 18.5124H96.4033ZM93.7795 16.7984H97.9036C98.6892 16.7984 99.4382 16.8458 99.9406 17.0536C101.041 17.5165 101.37 18.6344 101.37 19.4157C101.388 20.0257 101.161 20.6179 100.738 21.062C100.437 21.3483 100.071 21.5583 99.6711 21.674L101.676 24.9756H98.7805L97.182 22.0918H96.4102V24.9756H93.7795V16.7984Z"
        fill="white"
      />
      <path
        d="M102.818 16.7984H105.592L107.456 19.8922C107.913 20.7368 108.043 20.9807 108.463 21.8953C108.394 21.0959 108.378 20.922 108.358 20.0074V16.7984H110.804V24.9756H107.983L106.309 22.1505C105.89 21.453 105.509 20.7339 105.168 19.9961C105.227 20.7368 105.236 20.922 105.25 21.6627V24.9756H102.818V16.7984Z"
        fill="white"
      />
      <path
        d="M46.6466 27.7578V28.5957H45.3152V31.4818H43.9565V28.5957H42.6183V27.7578H46.6466Z"
        fill="white"
      />
      <path
        d="M48.0121 29.6164C48.0121 30.19 48.3273 30.7862 49.1402 30.7862C49.3281 30.7947 49.5154 30.7583 49.686 30.68C50.0993 30.4745 50.2432 30.0026 50.2432 29.6164C50.2432 29.2302 50.0674 28.4624 49.1379 28.4489C48.2907 28.4489 48.0121 29.106 48.0121 29.6164ZM50.4601 27.8414C50.8135 27.9825 51.1149 28.2271 51.3238 28.5423C51.5328 28.8575 51.6394 29.2283 51.6293 29.6051C51.6333 29.9605 51.5393 30.3102 51.3576 30.6168C51.239 30.8105 51.084 30.9799 50.9009 31.1159C50.3893 31.5179 49.7796 31.6127 49.0991 31.6127C47.7906 31.6127 47.2517 31.1611 46.9754 30.7794C46.7291 30.4546 46.6033 30.0559 46.6192 29.6503C46.6105 29.2368 46.7309 28.8307 46.964 28.4873C47.4915 27.7511 48.3867 27.6291 49.0512 27.6201C49.5303 27.6009 50.0084 27.6761 50.4579 27.8414H50.4601Z"
        fill="white"
      />
      <path
        d="M53.4471 29.6164C53.4471 30.19 53.7622 30.7862 54.5774 30.7862C54.7639 30.7951 54.9497 30.7586 55.1186 30.68C55.5342 30.4745 55.6781 30.0026 55.6781 29.6164C55.6781 29.2302 55.4977 28.4624 54.5774 28.4511C53.7211 28.4489 53.4471 29.106 53.4471 29.6164ZM55.8973 27.8414C56.2501 27.9832 56.5508 28.2279 56.7593 28.543C56.9678 28.8582 57.0741 29.2287 57.0642 29.6051C57.0682 29.9605 56.9742 30.3102 56.7925 30.6168C56.6745 30.8109 56.5193 30.9805 56.3358 31.1159C55.8265 31.5179 55.2191 31.6127 54.5386 31.6127C53.2278 31.6127 52.6912 31.1611 52.4126 30.7794C52.1657 30.4548 52.0391 30.0562 52.0541 29.6503C52.0471 29.2363 52.169 28.8303 52.4035 28.4873C52.9287 27.7511 53.8238 27.6291 54.4906 27.6201C54.9697 27.6012 55.4478 27.6764 55.8973 27.8414Z"
        fill="white"
      />
      <path d="M59.0121 27.7623V30.5739H60.9303V31.4817H57.6602V27.7623H59.0121Z" fill="white" />
      <path
        d="M73.2753 27.7623V28.6002H70.6606V29.2054H73.0789V30.0477H70.6538V30.6371H73.3278V31.4817H69.311V27.7623H73.2753Z"
        fill="white"
      />
      <path
        d="M75.1022 29.6638C75.0875 29.8132 75.106 29.9639 75.1563 30.1055C75.2065 30.2471 75.2874 30.3761 75.3933 30.4836C75.4992 30.5911 75.6275 30.6744 75.7693 30.7279C75.911 30.7813 76.0629 30.8035 76.2143 30.793C76.9541 30.793 77.3241 30.2916 77.3241 29.6638C77.3241 28.9434 76.8971 28.4534 76.2097 28.4647C75.4698 28.4624 75.1022 29.0631 75.1022 29.6638ZM77.2122 31.4863C76.8835 31.5748 76.5434 31.6152 76.2029 31.606C75.6294 31.6402 75.0583 31.5092 74.5587 31.2288C74.2887 31.0621 74.0675 30.8286 73.9169 30.5513C73.7663 30.274 73.6915 29.9627 73.7001 29.648C73.6933 29.1726 73.8603 28.7106 74.1705 28.3473C74.7391 27.6991 75.6411 27.6382 76.2257 27.6382C76.6504 27.6382 77.7854 27.6382 78.3837 28.5144C78.6091 28.8516 78.7203 29.251 78.7011 29.6548C78.71 30.1071 78.5471 30.5463 78.2444 30.8856L78.8952 31.1904L78.4156 32.1096L77.2122 31.4863Z"
        fill="white"
      />
      <path
        d="M79.2971 27.7714H80.6398V29.8829C80.6241 30.0699 80.6612 30.2576 80.7472 30.4249C80.8326 30.5493 80.9499 30.6492 81.0871 30.7143C81.2243 30.7793 81.3765 30.8073 81.5281 30.7952C81.7618 30.8102 81.9923 30.7358 82.1721 30.5875C82.2576 30.4881 82.3218 30.3726 82.3611 30.2481C82.4003 30.1235 82.4137 29.9924 82.4005 29.8626V27.7714H83.7409V29.7203C83.7409 30.1584 83.7409 30.6236 83.524 30.9307C83.1129 31.5631 82.1242 31.6218 81.5259 31.6218C80.6901 31.6218 80.2174 31.5066 79.9274 31.3372C79.7169 31.2238 79.5441 31.0526 79.4299 30.8442C79.3156 30.6358 79.2647 30.3992 79.2834 30.1629L79.2971 27.7714Z"
        fill="white"
      />
      <path d="M84.6292 27.7623H85.9765V31.4795H84.6292V27.7623Z" fill="white" />
      <path
        d="M88.1322 29.3319H89.0046C89.2329 29.3319 89.5754 29.3319 89.5754 28.9683C89.5761 28.9121 89.5639 28.8565 89.5398 28.8057C89.5157 28.7549 89.4802 28.7101 89.4361 28.6747C89.3384 28.6176 89.225 28.5923 89.1119 28.6024H88.1322V29.3319ZM86.7895 27.7511H89.3631C89.8426 27.7511 90.2765 27.7714 90.5779 28.0333C90.6963 28.1516 90.7881 28.2931 90.8475 28.4487C90.9069 28.6043 90.9326 28.7706 90.9228 28.9367C90.932 29.214 90.8441 29.4861 90.6738 29.7067C90.3267 30.129 89.7604 30.1584 89.1484 30.1584H88.1322V31.4704H86.7895V27.7511Z"
        fill="white"
      />
      <path
        d="M91.3315 27.7623H93.1173L94.065 30.19L95.0126 27.7623H96.7915V31.4817H95.4602V29.1015L94.5719 31.4817H93.5329L92.6537 29.1105V31.4817H91.3315V27.7623Z"
        fill="white"
      />
      <path
        d="M101.621 27.7623V28.6002H99.0066V29.2054H101.425V30.0477H99.002V30.6371H101.674V31.4817H97.6593V27.7623H101.621Z"
        fill="white"
      />
      <path
        d="M102.331 27.7714H103.444L104.599 29.0247C104.837 29.271 105.06 29.5312 105.266 29.8038C105.243 29.2935 105.241 29.1264 105.241 28.6137V27.7714H106.565V31.4818H105.446L104.076 29.9416C103.884 29.7158 103.82 29.6435 103.651 29.4312C103.665 30.0748 103.665 30.2171 103.67 30.6891V31.4818H102.341L102.331 27.7714Z"
        fill="white"
      />
      <path
        d="M110.952 27.7578V28.5957H109.621V31.4818H108.264V28.5957H106.926V27.7578H110.952Z"
        fill="white"
      />
      <path
        d="M65.5842 31.4569H64.753V29.9823H63.255V29.1603H64.753V27.6856H65.5842V29.1603H67.0686V29.9823H65.5842V31.4569Z"
        fill="white"
      />
      <path
        d="M111.354 18.0382C111.344 17.7817 111.413 17.5283 111.55 17.3104C111.687 17.0925 111.887 16.9201 112.124 16.8155C112.361 16.7108 112.624 16.6786 112.88 16.7229C113.135 16.7673 113.372 16.8862 113.558 17.0644C113.745 17.2427 113.874 17.472 113.928 17.7231C113.982 17.9741 113.959 18.2354 113.861 18.4734C113.764 18.7113 113.597 18.9151 113.382 19.0584C113.166 19.2018 112.913 19.2783 112.653 19.278C112.484 19.286 112.316 19.2598 112.157 19.2009C111.999 19.142 111.854 19.0518 111.733 18.9356C111.611 18.8195 111.515 18.68 111.45 18.5257C111.384 18.3713 111.352 18.2054 111.354 18.0382ZM113.535 18.0382C113.543 17.9188 113.527 17.7988 113.487 17.6859C113.447 17.5729 113.383 17.4694 113.301 17.3817C113.218 17.294 113.118 17.2241 113.007 17.1763C112.896 17.1285 112.777 17.1038 112.656 17.1038C112.534 17.1038 112.415 17.1285 112.304 17.1763C112.193 17.2241 112.093 17.294 112.01 17.3817C111.928 17.4694 111.864 17.5729 111.824 17.6859C111.784 17.7988 111.768 17.9188 111.776 18.0382C111.768 18.1577 111.784 18.2776 111.824 18.3905C111.864 18.5035 111.928 18.607 112.01 18.6947C112.093 18.7824 112.193 18.8523 112.304 18.9001C112.415 18.9479 112.534 18.9726 112.656 18.9726C112.777 18.9726 112.896 18.9479 113.007 18.9001C113.118 18.8523 113.218 18.7824 113.301 18.6947C113.383 18.607 113.447 18.5035 113.487 18.3905C113.527 18.2776 113.543 18.1577 113.535 18.0382ZM112.473 18.7157H112.142V17.3652H112.651C113.009 17.3652 113.229 17.4556 113.229 17.7762C113.229 18.0247 113.076 18.1172 112.84 18.1285L113.188 18.7089H112.866L112.555 18.1489H112.471L112.473 18.7157ZM112.713 17.9185C112.831 17.9185 112.895 17.8892 112.895 17.7491C112.895 17.6091 112.747 17.6091 112.637 17.6091H112.466V17.9185H112.713Z"
        fill="white"
      />
    </svg>
  );
}
