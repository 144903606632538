import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@/app/utils/constants';
import { getCustomerProjects } from '@/app/services/projectService';
import { useIsAuthenticated } from '@/app/hooks/useIsAuthenticated';
import routes from '@/app/utils/routes';
import { useParams, usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useContext } from 'react';
import { AppContext } from '@/app/appProvider';
import { isRouteInPathname } from '@/app/utils/helpers';
import { isEligibleForMyProjectsSpa } from '@/app/utils/myProjectsSpaEligibilityRule';
import { getRetailerConfig } from '@/app/services/retailerService';

export const useGetCustomerProjects = () => {
  const router = useRouter();
  const { setCategories, srcPartnerId } = useContext(AppContext);
  const { workOrderId } = useParams();
  const { isAuthenticated } = useIsAuthenticated();
  const params = useSearchParams();
  const pathname = usePathname();
  const excludeRoutes = [routes.paymentHistory, routes.profile, routes.signNow, routes.ingressAuth];
  const retailerConfig = getRetailerConfig(srcPartnerId);

  return useQuery({
    queryKey: [QueryKey.CUSTOMER_PROJECTS],
    queryFn: async () => {
      const { data } = await getCustomerProjects();
      setCategories(data.categories);

      // If there is a redirection parameter present, let it resolve to avoid intercepting the initial push
      if (!!params.get('target')) {
        return data;
      }

      // Send users not eligible for MyProjectsSPA back to v1
      if (!isEligibleForMyProjectsSpa(data)) {
        router.push(`${routes.signOut}?target=${retailerConfig?.legacyMyProjectsUrl}/my-projects`);
        return data;
      }

      // If a WO in pathname is available e.g. after refresh wo details page, navigate to the WO details page
      if (data && workOrderId && !excludeRoutes.some((r) => isRouteInPathname(r, pathname))) {
        router.push(`${routes.workOrder}/${workOrderId}`);
        return data;
      }

      // If current pathname is included in exclude routes, don't navigate to the WO details page
      if (data && excludeRoutes.some((r) => isRouteInPathname(r, pathname))) {
        return data;
      }

      // Initial login flow: When first landing on this layout, if a WO is available, navigate to the WO details page
      if (data && data.categories.length > 0 && data.categories[0].workOrderIds.length > 0) {
        router.push(`${routes.workOrder}/${data.categories[0].workOrderIds[0]}`);
      }

      return data;
    },
    enabled: isAuthenticated,
  });
};
