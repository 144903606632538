import React, { useContext } from 'react';
import { getRetailerConfig } from '@/app/services/retailerService';
import { AppContext } from '@/app/appProvider';
import { useTranslations } from 'next-intl';

export default function Logo() {
  const appContext = useContext(AppContext);
  const retailerConfig = getRetailerConfig(appContext.srcPartnerId);
  const t = useTranslations('pages.logo');
  const logoSubtext = t('poweredByInstallationMadeEasy');

  return (
    <div className="text-center pt-1">
      {retailerConfig?.logo}
      {logoSubtext && <div className="logo-text fs-7 mt-1 fw-bold">{logoSubtext}</div>}
    </div>
  );
}
